@tailwind base;
@tailwind components;
@tailwind utilities;

/* Moved from index.html: */
/* html{
  height: 100%;
}

body{
  min-height: 100vh;
}

#root{
  min-height: 100vh;
}

iframe{
  margin-left: auto !important;
  margin-right: auto !important;
}
 */

/* color from tailwind config */
/* @layer base {
  :root {
    --color-primary: #f9fafb;
    --color-secondary: #f3f4f6;
    --color-tertiary: #e5e7eb;
    --color-text: #374
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #20272b;  /* ebbidark */
  background-color: #fbf9ee;  /* ebbilight */

  min-height: 100vh; /* Add this line to make body minimum full screen */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  min-height: 100vh; /* Add this line to make #root minimum full screen */
  position: relative;
  padding-bottom: 170px; /* TODO: fix on desktop */
}